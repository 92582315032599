import { AnimationEngine } from './modules/Animation/lib/AnimationEngine';
if (!window.wa) {
    window.wa = {};
}
Object.assign(window.wa, {
    animation: {
        createAnimation(args) {
            return new AnimationEngine(args);
        },
    },
});
