import {
    PerspectiveCamera,
    Scene,
    Clock,
    UnsignedByteType,
    FloatType,
    AnimationMixer,
    DirectionalLight,
    AmbientLight,
    WebGLRenderer,
    PMREMGenerator,
    sRGBEncoding,
    DataTexture,
    PointLight,
    LinearToneMapping,
} from 'three';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader';
import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { OrbitControls } from './OrbitControls';

export class AnimationEngine {
    /**
     * @var {AnimationEngineArgs} options
     *
     * @param {HTMLElement} options.target Container to append stage to
     * @param {int} options.width Width of stage (use half width of the full video)
     * @param {int} options.height Height of stage (use same height as the video)
     * @param {int} options.fov Field of view
     * @param {string} options.gltfUrl GLTF source url
     * @param {string} options.hdrUrl HDR source url
     *
     * @param {boolean} features.controls Enable/disable controls
     * @param {boolean} features.antialias Enable/disable antialiasing
     */
    constructor(options, features = {})
    {
        this.target = options.target;
        this.width = options.width;
        this.height = options.height;
        this.fov = options.fov || 20;
        this.gltfUrl = options.gltfUrl;
        this.hdrUrl = options.hdrUrl;
        this.features = {
            controls: (typeof features.controls !== 'undefined') ? features.controls : true,
            antialias: (typeof features.antialias !== 'undefined') ? features.antialias : true
        };
    }

    async replace(gltfUrl)
    {
        let newContainer = document.createElement("div");
        this.target.after(newContainer);

        let newAnimation = new AnimationEngine({
            target: newContainer,
            gltfUrl: gltfUrl,
            width: this.width,
            height: this.height,
            fov: this.fov,
            hdrUrl: this.hdrUrl,
        });
        await newAnimation.setup();
        newAnimation.start();
        this.target.remove();
    }

    async setup()
    {
        this.scene = new Scene();
        this.clock = new Clock();
        this.camera = this.getCamera(this.width, this.height, this.fov);

        this.scene.add(this.camera);

        this.getLights().forEach((light) => {
            this.scene.add(light);
        });

        this.renderer = this.getRenderer(this.width, this.height, this.features.antialias);

        const assets = await this.loadAssets(this.hdrUrl, this.gltfUrl);
        this.hdrParser(assets[0], this.scene, this.renderer);
        this.gltfParser(assets[1], this.scene);

        this.target.appendChild(this.renderer.domElement);

        if (this.features.controls) {
            this.controls = this.configureControls(this.camera, this.renderer.domElement);
        }
        return this;
    }

    getRenderer(width, height, antialias = true)
    {
        const renderer = new WebGLRenderer({ antialias: antialias, alpha: true });

        renderer.setPixelRatio(1); // Mobil 1
        renderer.setSize(width, height, false);
        renderer.setClearColor(0x000000,0);

        renderer.outputEncoding = sRGBEncoding;
        renderer.toneMapping = LinearToneMapping;
        renderer.toneMappingExposure = 0.50;

        return renderer;
    }

    configureControls(camera, domElement)
    {
        const controls = new OrbitControls(camera, domElement);

        controls.enableDamping = true;
        controls.dampingFactor = 0.05;
        controls.rotateSpeed = 0.02;
        controls.target.set( 0, 0, 0 );
        controls.dispose();

        document.addEventListener('mousemove', controls.handleMouseMoveRotate, { passive: true });
        return controls;
    }

    getCamera(width, height, fov)
    {
        const camera = new PerspectiveCamera(fov, width / height, 1, 10000);
        // Camera position
        camera.position.x = 16;
        camera.position.y = 8;
        camera.position.z = 16;

        camera.lookAt(0,0,0);

        return camera;
    }

    getLights()
    {
        const lights = [
            new AmbientLight(0x666666)
        ];

        const dirLight = new DirectionalLight(0xdfebff, 0.5);
        dirLight.position.set( 5, 10, 5 );
        lights.push(dirLight);

        const leftPointLight = new PointLight(0x1763E9, 2, 100);
        leftPointLight.position.set( 8, 10, -2  );
        lights.push(leftPointLight);

        const rightPointLight = new PointLight(0xFFBC1F, 2, 100);
        rightPointLight.position.set( -2, 10, 8);
        lights.push(rightPointLight);

        return lights;
    }

    loadAssets(hdrUrl, gltfUrl)
    {
        const gltfLoader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/app/themes/wa-theme/modules/Animation/lib/draco/gltf/');
        gltfLoader.setDRACOLoader( dracoLoader );

        return Promise.all([
            new EXRLoader()
                .setDataType(FloatType)
                .loadAsync(hdrUrl),
            gltfLoader
                .loadAsync(gltfUrl)
        ]);
    }

    /**
     * @param {DataTexture} texture
     * @param {Scene} scene
     * @param {WebGLRenderer} renderer
     */
    hdrParser(texture, scene, renderer)
    {
        const pmremGenerator = new PMREMGenerator(renderer);
        pmremGenerator.compileEquirectangularShader();

        scene.environment = pmremGenerator.fromEquirectangular(texture).texture;

        texture.dispose();
        pmremGenerator.dispose();
    }

    /**
     * @param {GLTF} gltf
     * @param {Scene} scene
     */
    gltfParser(gltf, scene)
    {
        gltf.scene.traverse((node) => {
            if (node.isMesh) {
                if (node.name === 'shadowPlane') {
                    node.material.opacity = 0.6;
                }
            }
        });
        scene.add(gltf.scene);

        this.mixer = new AnimationMixer(gltf.scene);

        gltf.animations.forEach((clip) => {
            this.mixer.clipAction(clip).play();
        });
    }

    render()
    {
        if (this.mixer) {
            this.mixer.update(this.clock.getDelta());
        }

        if (this.controls) {
            this.controls.update();
        }

        this.renderer.render(this.scene, this.camera);

        if (this.animating) {
            requestAnimationFrame(() => {
                this.render();
            });
        }
        return this;
    }

    start()
    {
        this.animating = true;
        this.render();
    }

    stop()
    {
        this.animating = false;
    }
}

